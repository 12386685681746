import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | About Shenzhen Starnoon Technology Co., LTD Sports Bar
			</title>
			<meta name={"description"} content={"At Shenzhen Starnoon Technology Co., LTD Sports Bar, we've created a place that's more than just a venue for watching sports — it's a community hub where the love of the game meets the joy of camaraderie."} />
			<meta property={"og:title"} content={"About Us | About Shenzhen Starnoon Technology Co., LTD Sports Bar"} />
			<meta property={"og:description"} content={"At Shenzhen Starnoon Technology Co., LTD Sports Bar, we've created a place that's more than just a venue for watching sports — it's a community hub where the love of the game meets the joy of camaraderie."} />
			<meta property={"og:image"} content={"https://axiolblade.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://axiolblade.live/img/723593.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://axiolblade.live/img/723593.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://axiolblade.live/img/723593.png"} />
			<meta name={"msapplication-TileImage"} content={"https://axiolblade.live/img/723593.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://axiolblade.live/img/5.jpg"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					About Shenzhen Starnoon Technology Co., LTD Sports Bar
				</Text>
			</Box>
			<Box
				flex-direction="column"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				width="33.3333%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					The Spirit of Shenzhen Starnoon Technology Co., LTD
					<br />
					At Shenzhen Starnoon Technology Co., LTD Sports Bar, we've created a place that's more than just a venue for watching sports — it's a community hub where the love of the game meets the joy of camaraderie. Our story isn't about a single person or event – it's about the countless moments of connection and celebration shared by our guests.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				width="33.3333%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					A Game Plan for Relaxation and Fun
					<br />
					Our philosophy is simple: provide a space where sports fans can unwind, enjoy a game, and have a great time without the pressures of everyday life. Shenzhen Starnoon Technology Co., LTD is designed to be your friendly neighborhood retreat where every visit feels like a touchdown.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				display="flex"
				width="33.3333%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 60% 0px"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
				>
					<Image
						bottom="0px"
						min-height="100%"
						src="https://axiolblade.live/img/6.jpg"
						display="block"
						width="100%"
						left={0}
						right={0}
						object-fit="cover"
						position="absolute"
						top="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					More Than Just a Sports Bar
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--darkL2"
					width="70%"
					lg-width="100%"
					sm-text-align="left"
				>
					Shenzhen Starnoon Technology Co., LTD is not just about catching the latest game. It's a sanctuary where you can relax, challenge friends to light-hearted trivia, or simply engage in good-natured banter with our knowledgeable and friendly bar staff.
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
									Crafting a Winning Atmosphere
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								We've put thought into every detail, from our sports-themed decor to the layout of our space, which encourages both lively group interactions and cozy, intimate conversations. It’s a place where new friendships are formed over shared interests and where every night is a chance to be part of something exciting.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
									Join Our Roster
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
								Remember, Shenzhen Starnoon Technology Co., LTD is more than just its walls and screens – it's about the people who fill it. Whether you're a die-hard sports fanatic or just looking for a pleasant evening, you're always in the right league with us.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://axiolblade.live/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://axiolblade.live/img/8.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Step Into Shenzhen Starnoon Technology Co., LTD
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					We invite you to become a part of our ongoing story. Join us at Shenzhen Starnoon Technology Co., LTD, where every game is an experience and every guest is part of the team.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
				>
					CONTACT US
				</Link>
			</Box>
		</Section>
		<Components.Foopter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});